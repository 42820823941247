import Link from 'next/link'
import { forwardRef, useEffect } from 'react'
import { CgClose } from 'react-icons/cg'
import { LogoSquare } from './Logo'

const ExhibitionPanel = ({ className, open, onCloseClick, ...props }) => {

  useEffect(_ => {
    const onClick = _ => onCloseClick()
    const onKeyUp = ({ key }) => key === 'Escape' && onCloseClick()
    open && window.addEventListener('click', onClick)
    open && window.document.addEventListener('keyup', onKeyUp)
    return _ => {
      window.removeEventListener('click', onClick)
      window.document.removeEventListener('keyup', onKeyUp)
    }
  }, [open])

  return (
    <div {...props} className={'full-height-minus-header overflow-y-auto absolute md:static inset-0 md:pt-0 h-screen md:h-auto bg-gray-50 md:rounded md:shadow w-screen md:w-96 text-gray-700 tracking-normal ' + className}>
      <CgClose onClick={onCloseClick} size='1.5rem' className='absolute md:hidden top-6 right-6 md:top-4 md:right-4 cursor-pointer text-red-300 hover:text-red-400' />
      <div className='divide-y pt-20 md:pt-0'>
        <Link href='/' as='/' >
          <button type='button' className='w-full group flex text-gray-200 items-center cursor-pointer p-6 py-12 md:py-5 space-x-4 focus:outline-none'>
            {/* <h1 className='text-6xl md:text-5xl font-display text-gray-200'>1</h1> */}
            <LogoSquare width='2rem' height='2rem' />
            <div className='flex-grow text-left'>
              <h1 className='font-display font-medium text-3xl md:text-2xl text-gray-600 group-hover:text-red-400 tracking-wide'>Atrium</h1>
              <h3 className='text-xs text-gray-500 tracking-wider group-hover:text-red-400'>The home of The Crypt</h3>
            </div>
          </button>
        </Link>
        <Link href='/exhibitions/flashback'>
          <button type='button' className='w-full group flex items-center cursor-pointer p-6 py-12 md:py-5 space-x-4 focus:outline-none'>
            <h1 className='text-6xl md:text-5xl font-display text-gray-200'>1</h1>
            <div className='flex-grow text-left'>
              <h1 className='font-display font-medium text-3xl md:text-2xl text-gray-600 group-hover:text-red-400 tracking-wide'>Flashback</h1>
              <h3 className='text-xs text-gray-500 tracking-wider group-hover:text-red-400'>A tribute to rad 1980s tech</h3>
            </div>
          </button>
        </Link>
        <Link href='/exhibitions/unseen-histories'>
          <button type='button' className='group w-full flex items-center cursor-pointer p-6 py-12 md:py-5 space-x-4 focus:outline-none'>
            <h1 className='text-6xl md:text-5xl font-display text-gray-200'>2</h1>
            <div className='flex-grow text-left'>
              <h1 className='font-display font-medium text-3xl md:text-2xl text-gray-600 group-hover:text-red-400 tracking-wide'>Unseen Histories</h1>
              <h2 className='text-gray-500 text-xs tracking-wider group-hover:text-red-400'>Jordan J. Lloyd’s Unseen Histories</h2>
            </div>
          </button>
        </Link>
        <Link href='/exhibitions/alternate-dimensions'>
          <button type='button' className='group w-full flex items-center cursor-pointer p-6 py-12 md:py-5 space-x-4 focus:outline-none'>
            <h1 className='text-6xl md:text-5xl font-display text-gray-200'>3</h1>
            <div className='flex-grow text-left'>
              <h1 className='font-display font-medium text-3xl md:text-2xl text-gray-600 group-hover:text-red-400 tracking-wide'>Alternate Dimensions</h1>
              <h2 className='text-gray-500 text-xs tracking-wider group-hover:text-red-400'>by Margaret Garrett</h2>
            </div>
          </button>
        </Link>
        <Link href='/exhibitions/the-making-of-heroes'>
          <button type='button' className='group w-full flex items-center cursor-pointer p-6 py-12 md:py-5 space-x-4 focus:outline-none'>
            <h1 className='text-6xl md:text-5xl font-display text-gray-200'>4</h1>
            <div className='flex-grow text-left'>
              <h1 className='font-display font-medium text-3xl md:text-2xl text-gray-600 group-hover:text-red-400 tracking-wide'>Heroes</h1>
              <h2 className='text-gray-500 text-xs tracking-wider group-hover:text-red-400'>The Making of Heroes</h2>
            </div>
          </button>
        </Link>
        <Link href='/exhibitions/one-giant-leap'>
          <button type='button' className='group w-full flex items-center cursor-pointer p-6 py-12 md:py-5 space-x-4 focus:outline-none'>
            <h1 className='text-6xl md:text-5xl font-display text-gray-200'>5</h1>
            <div className='flex-grow text-left'>
              <h1 className='font-display font-medium text-3xl md:text-2xl text-gray-600 group-hover:text-red-400 tracking-wide'>One Giant Leap</h1>
              <h2 className='text-gray-500 text-xs tracking-wider group-hover:text-red-400'>Iconic spacesuits from the silver screen</h2>
            </div>
          </button>
        </Link>
        <Link href='/exhibitions/prop-store'>
          <button type='button' className='group w-full flex items-center cursor-pointer p-6 py-12 md:py-5 space-x-4 focus:outline-none'>
            <h1 className='text-6xl md:text-5xl font-display text-gray-200'>6</h1>
            <div className='flex-grow text-left'>
              <h1 className='font-display font-medium text-3xl md:text-2xl text-gray-600 group-hover:text-red-400 tracking-wide'>Prop Store</h1>
              <h2 className='text-gray-500 text-xs tracking-wider group-hover:text-red-400'>Film &amp; Television Memorabilia</h2>
            </div>
          </button>
        </Link>
        <Link href='/exhibitions/hollywood-greats'>
          <button type='button' className='group w-full flex items-center cursor-pointer p-6 py-12 md:py-5 space-x-4 focus:outline-none'>
            <h1 className='text-6xl md:text-5xl font-display text-gray-200'>7</h1>
            <div className='flex-grow text-left'>
              <h1 className='font-display font-medium text-3xl md:text-2xl text-gray-600 group-hover:text-red-400 tracking-wide'>Hollywood Greats</h1>
              <h2 className='text-gray-500 text-xs tracking-wider group-hover:text-red-400'>Iconic portraits, scene stills and production shots</h2>
            </div>
          </button>
        </Link>
        <div className='flex items-center justify-center text-gray-300 font-normal cursor-pointer p-6 py-4 text-right'>
          <h1 className='self-end'>More exhibitions coming soon</h1>
        </div>
      </div>
    </div>
  )
}

export default ExhibitionPanel
