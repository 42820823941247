import Link from 'next/link'
import { connect } from 'react-redux'
import Button from './Button'
import { openPopout } from '../redux/site'
import { signOut } from '../redux/auth'
import { useRouter } from 'next/router'
import UserButton from './user/button'
import { Turn as Hamburger } from 'hamburger-react'
import UserPanel from './UserPanel'
import { forwardRef, useRef, useState } from 'react'
import ExhibitionPanel from './ExhibitionPanel'
import { LogoSquare } from './Logo'
import { Popover, Transition, Dialog } from '@headlessui/react'
import { Fragment } from 'react'

const Header = ({ auth, openPopout, className, itemColor = 'text-gray-300', forwardRef }) => {
  const router = useRouter()
  const [showExhibitionPanel, setShowExhibitionPanel] = useState(false)
  const [showUserPanel, setShowUserPanel] = useState(false)

  const onConnectClick = _ => {
    setShowUserPanel(false)
    setShowExhibitionPanel(false)
    openPopout('auth')
    if (router.pathname === '/about') router.push('/')
  }

  const onHelpClick = _ => {
    setShowUserPanel(false)
    setShowExhibitionPanel(false)
    openPopout('onboarding')
    if (router.pathname === '/about') router.push('/')
  }

  const closeExhibitionPanel = _ => {
    setShowExhibitionPanel(false)
    setShowUserPanel(false)
  }

  const openExhibitionPanel = _ => {
    setShowUserPanel(false)
    setShowExhibitionPanel(true)
  }

  const onUserPanelNavigation = _ => {
    // setShowUserPanel(false)
    // setShowExhibitionPanel(false)
  }

  const toggleUserPanel = _ => {
    setShowUserPanel(!showUserPanel)
    setShowExhibitionPanel(false)
  }

  const onHamburgerMenuToggle = toggled => {
    setShowExhibitionPanel(false)
    setShowUserPanel(toggled)
  }

  return (
    <header className={'relative flex container flex-col mx-auto w-full h-screen px-6 items-end ' + className}>
      {/* <div className='container flex flex-col mx-auto w-full px-6'> */}
        <div ref={forwardRef} className={'pointer-events-auto flex md:space-x-12 w-full h-20 items-center ' + itemColor}>
          <div className='flex flex-grow item-center'>
            <Link href='/'>
              <div className='cursor-pointer flex items-center space-x-2 h-24 opacity-90 text-white hover:text-red-400 hover:opacity-100'>
                <LogoSquare width='2rem' height='2rem' />
                <h2 className='font-display uppercase tracking-wide'>THE CRYPT - beta</h2>
              </div>
            </Link>
          </div>
          <div className='md:flex flex-col items-end cursor-pointer h-full text-sm font-body font-medium'>
            <div onClick={openExhibitionPanel} className='hidden md:block h-but select-none focus:outline-none focus:ring-0 px-7 max-w-min uppercase tracking-xl text-right'>Exhibitions</div>
            <Transition
              // as={Fragment}
              className='md:pt-7'
              show={showExhibitionPanel && !showUserPanel}
              enter='absolute md:static inset-0 transition duration-100 ease-out'
              enterFrom='transform scale-95 opacity-0'
              enterTo='transform scale-100 opacity-100'
              leave='transition duration-75 ease-out'
              leaveFrom='transform scale-100 opacity-100'
              leaveTo='transform scale-95 opacity-0'
            >
              <ExhibitionPanel open={showExhibitionPanel && !showUserPanel} onCloseClick={closeExhibitionPanel} className='md:transform md:translate-x-20 z-50'/>
            </Transition>
          </div>
          <Link href='/about' name='About'>
            <div className='hidden md:flex flex-col items-end cursor-pointer h-full text-sm font-body font-medium'>
              <div className='h-but select-none focus:outline-none focus:ring-0 max-w-min uppercase tracking-xl text-right'>About</div>
            </div>
          </Link>
          {!auth.user && (
            <div className='md:hidden transform translate-x-4'>
              <Hamburger label='Show menu' color={showUserPanel ? 'rgb(82, 82, 82)' : 'rgb(250, 250, 250)'} hideOutline={false} size={20} toggled={showUserPanel} toggle={onHamburgerMenuToggle} />
            </div>
          )}
          {!auth.user && (
            <Button className='hidden md:flex m-4' onClick={onConnectClick}>
              Connect
            </Button>
          )}
          {auth.user && auth.user.uid && <UserButton account={auth.user.uid} className='my-3 flex-shrink-0' onClose={_ => setShowUserPanel(false)} onClick={toggleUserPanel} />}
        </div>
        <UserPanel show={/*!!auth.user && */ showUserPanel && !showExhibitionPanel} auth={auth} onCloseClick={_ => setShowUserPanel(false)} onConnectClick={onConnectClick} onExhibitionsClick={openExhibitionPanel} onHelpClick={onHelpClick} onNavigation={onUserPanelNavigation} />
    </header>
  )
}

const mapStateToProps = ({ auth, site }, ownProps) => ({
  ...ownProps,
  auth,
  isMenuVisible: site.isMenuVisible
})

const ConnectedHeader = connect(mapStateToProps, { openPopout, signOut })(Header)

export default forwardRef((props, ref) => <ConnectedHeader {...props} forwardRef={ref} />)
