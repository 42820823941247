import jazzicon from '@metamask/jazzicon'
import { useEffect, useRef } from 'react'

const UserIcon = ({ account, size = 36, className }) => {
  const iconRef = useRef()

  useEffect(_ => {
    let el
    if (account) {
      const addr = account.slice(2, 10)
      const seed = parseInt(addr, 16)
      el = jazzicon(size, Math.round(seed))
      el.className = 'pointer-events-none shadow'
      if (account) iconRef.current.appendChild(el)
    }
    return _ => iconRef.current && el && iconRef.current.removeChild(el)
  }, [account, size])

  return <div ref={iconRef} className={'flex items-center justify-center rounded-full ' + className} />
}

export default UserIcon
