export const LogoSquare = ({ className = '', ...props }) => (
  <svg className={'fill-current ' + className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 57.51 50' width='3rem' height='3rem' {...props}>
    <path d='M.77,18.75l-.05-.14Z' transform='translate(0)' />
    <path d='M1.31,19.89Z' transform='translate(0)' />
    <path d='M56.2,20v0Z' transform='translate(0)' />
    <path d='M53.77,6.76V0H41.3a34.45,34.45,0,0,1,7.75,3.18A22,22,0,0,1,53.77,6.76Z' transform='translate(0)' />
    <path d='M3.77,43.24V50H16.24a34,34,0,0,1-7.75-3.18A22,22,0,0,1,3.77,43.24Z' transform='translate(0)' />
    <path d='M8.36,3.18A33.69,33.69,0,0,1,16.07,0H3.77V6.67A21.33,21.33,0,0,1,8.36,3.18Z' transform='translate(0)' />
    <path d='M14.34,16.67a14.22,14.22,0,0,0-4,3.28,13.4,13.4,0,0,0,2.74,2.52c.39-.27.79-.53,1.22-.79a26.07,26.07,0,0,1,9-3,49.22,49.22,0,0,1,11.25.06,26.28,26.28,0,0,1,8.57,3c.46.27.89.55,1.3.84A13.17,13.17,0,0,0,47.16,20a14.64,14.64,0,0,0-4-3.35C35.14,12.06,22.25,12.07,14.34,16.67Z' transform='translate(0)' />
    <path d='M49.18,46.82A33.58,33.58,0,0,1,41.46,50H53.77V43.33A22,22,0,0,1,49.18,46.82Z' transform='translate(0)' />
    <path d='M18.13,25A33.51,33.51,0,0,0,39.4,25,33.51,33.51,0,0,0,18.13,25Z' transform='translate(0)' />
    <path d='M49.18,36.78c-11.2,6.51-29.42,6.51-40.69,0C3.94,34.16,1.22,30.87.33,27.46a9.63,9.63,0,0,0,0,5c.89,3.41,3.61,6.7,8.16,9.32A38.27,38.27,0,0,0,23,46.32a34.44,34.44,0,0,0,11.28.06,38.34,38.34,0,0,0,14.9-4.58c4.47-2.6,7.15-5.85,8-9.23a9.85,9.85,0,0,0,0-5C56.33,30.93,53.65,34.18,49.18,36.78Z' transform='translate(0)' />
    <path d='M8.6,13.49a4.88,4.88,0,0,0-.12.75,3.23,3.23,0,0,0,0,.44,5,5,0,0,0,0,.55v.35a6.72,6.72,0,0,0,.16,1,.51.51,0,0,0,.07.31,4.6,4.6,0,0,1,.19.59h0a11.82,11.82,0,0,1,5.44-5.79,26.07,26.07,0,0,1,9-3,49.79,49.79,0,0,1,11.25.06,26.14,26.14,0,0,1,8.57,3,11.92,11.92,0,0,1,5.52,5.86h0c.08-.22.15-.44.21-.67v-.17a7.69,7.69,0,0,0,.18-1.17V14.47a7.5,7.5,0,0,0-.15-.93c-.57-2.49-2.49-4.9-5.8-6.81-8-4.6-20.86-4.6-28.77,0C11,8.56,9.13,11,8.6,13.49Z' transform='translate(0)' />
    <path d='M8.49,31.77A38.65,38.65,0,0,0,23,36.29a35,35,0,0,0,11.28.06,38.38,38.38,0,0,0,14.91-4.58c4.48-2.61,7.15-5.86,8-9.24a9.85,9.85,0,0,0,0-5,11,11,0,0,1-1,2.46v.06h0a14.7,14.7,0,0,1-1.57,2.26l-.13.15c-.31.36-.64.7-1,1.05l-.13.12c-.33.31-.68.62-1.06.93l-.16.13c-.39.32-.81.63-1.25.94l-.28.19c-.46.3-.93.61-1.43.9-.11.06-.24.12-.35.19h0l-.38.2h0l-1.24.64-.63.29-.94.42h0c-10,4.2-23.68,4.18-33.72-.06h0L11.11,28l-.64-.29c-.46-.22-.92-.46-1.36-.71h0l-.37-.2h0l-.22-.12c-.51-.29-1-.6-1.45-.91l-.29-.19c-.44-.31-.86-.62-1.26-.94l-.19-.15c-.37-.31-.73-.62-1.06-.93l-.13-.12c-.35-.34-.68-.69-1-1L3,22.28a12.75,12.75,0,0,1-.85-1.09h0c-.26-.37-.48-.75-.69-1.12L1.39,20h0a7.66,7.66,0,0,1-.62-1.2l-.05-.14a8.28,8.28,0,0,1-.39-1.19,9.63,9.63,0,0,0,0,5C1.22,25.84,3.93,29.14,8.49,31.77Z' transform='translate(0)' />
  </svg>
)
