import { Turn as Hamburger } from 'hamburger-react'
import Link from 'next/link'
import { FaDiscord, FaInstagram, FaTwitter } from 'react-icons/fa'
import { connect } from 'react-redux'
import { signOut } from '../redux/auth'
import Button from './Button'
import UserIcon from './user/icon'
import { MdContentCopy } from 'react-icons/md' 
import { openPopout } from '../redux/site'
import { Popover, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { CgClose } from 'react-icons/cg'

const UserPanel = ({ show, auth, signOut, onConnectClick, onExhibitionsClick, onHelpClick, onNavigation, onCloseClick, openPopout }) => {
  return (<>
    <Transition
      show={show}
      as={Fragment}
      enter='transition duration-100 ease-out'
      enterFrom='opacity-0'
      enterTo='opacity-100'
      leave='transition duration-75 ease-out'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
    >
      <div className='hidden md:inline-flex md:absolute top-20 rounded-sm bg-gray-50 w-3 h-3 transform rotate-45 -translate-x-3 translate-y-1' />
    </Transition>
    <Transition
      show={show}
      as={Fragment}
      enter='transform transition duration-100 ease-out'
      enterFrom='scale-95 opacity-0'
      enterTo='scale-100 opacity-100'
      leave='transform transition duration-75 ease-out'
      leaveFrom='scale-100  opacity-100'
      leaveTo='scale-95 opacity-0'
    ><div className='absolute pointer-events-auto inset-0 w-screen h-screen max-h-full overflow-y-auto md:h-auto md:static md:my-2 md:w-64 md:transform md:translate-x-3 bg-gray-50 md:rounded md:shadow select-none overflow-none'>
      <div className='text-3xl md:text-base my-auto md:h-auto text-gray-600 font-medium divide-y'>
        <div className='p-6 py-5 w-full flex items-center space-x-4 md:justify-end'>
          {!auth.user && (
            <Button className='md:hidden' onClick={onConnectClick}>
              Connect
            </Button>
          )}
          {auth.user && (
            <>
              <UserIcon className='md:hidden shadow' size={40} account={auth.user.uid} />
              <div className='flex flex-col w-full items-start md:items-end'>
                <h1 className='font-display text-3xl md:text-2xl tracking-wide capitalize'>{auth.user.displayName}</h1>
                <p className='w-full font-detail text-xs tracking-wide text-gray-300 truncate'>{auth.user.uid}</p>
              </div>
            </>
          )}
          <div className='md:hidden flex justify-end flex-grow w-full text-right'> 
            <CgClose size='1.5rem' onClick={onCloseClick} className=' cursor-pointer text-red-300 hover:text-red-400' />
          </div>
          {/* <div className='w-full flex justify-end md:hidden'>
            <Hamburger label='Show menu' color='rgb(82, 82, 82)' hideOutline={false} size={20} toggled='true' toggle={onCloseClick} />
          </div> */}
        </div>
        <div className='p-6 py-8 md:py-4 space-y-4'>
          <Link href='/'>
            <h1 className='cursor-pointer hover:text-red-400' onClick={_ => onNavigation()}>Home</h1>
          </Link>
          <Link href='/about'>
            <h1 className='cursor-pointer hover:text-red-400' onClick={_ => onNavigation()}>About</h1>
          </Link>
          <h1 className='cursor-pointer hover:text-red-400' onClick={onExhibitionsClick} >Exhibitions</h1>
          <h1 onClick={onHelpClick} className='cursor-pointer text-red-300'>
            Help
          </h1>
        </div>
        {auth.account && (
          <div className='p-6 py-8 md:py-4 space-y-4'>
            <h1 className='cursor-default text-gray-300 '>My Profile</h1>
            <h1 className='cursor-default text-gray-300'>My Collections</h1>
            <h1 className='cursor-pointer hover:text-red-400' onClick={_ => openPopout('groups')}>Groups</h1>
            <h1 className='cursor-pointer hover:text-red-400' onClick={_ => signOut()}>
              Log Out
            </h1>
          </div>
        )}
        <div className='md:hidden flex p-6 py-8 md:py-4 space-x-10 text-gray-300'>
          <a href='https://twitter.com/cryptdotart'>
            <FaTwitter size={20} />
          </a>
          <a href='https://instagram.com/crypt.art'>
            <FaInstagram size={20} />
          </a>
          <a href='https://discord.com/invite/KRfsXr9jBz'>
            <FaDiscord size={20} />
          </a>
        </div>
        <div className='p-6 py-8 md:py-4 space-x-4 flex font-detail text-xs tracking-widest uppercase md:justify-end'>
          <a className='text-gray-300 hover:text-red-400' href='https://festive-snap-bc7.notion.site/Virtual-Galleries-Limited-Privacy-Policy-5266cb0305dd404e97b7bf6839105532' target='_blank' rel='noreferrer'>
            Privacy
          </a>
          <a className='text-gray-300 hover:text-red-400' href='https://festive-snap-bc7.notion.site/Virtually-Galleries-Limited-Terms-of-use-b70c4398574147319075f44a02083b56' target='_blank' rel='noreferrer'>
            Terms
          </a>
        </div>
      </div>
    </div>
  </Transition>
  </>)
}

const mapStateToProps = ({ auth }, ownProps) => ({ ...ownProps, auth })
export default connect(mapStateToProps, { signOut, openPopout })(UserPanel)
