const Button = ({ children, className, disabled = false, ...rest }) => {
  let style = className + ' min-w-max focus:outline-none rounded py-3 px-6 font-detail uppercase font-medium text-xs tracking-xl text-white text-center '
  style += disabled ? ' bg-gray-200 cursor-wait' : ' select-none bg-red-400 hover:bg-red-500 hover:shadow-md cursor-pointer shadow'
  // style += ' '
  return (
    <div {...rest} className={style}>
      {children}
    </div>
  )
}

Button.defaultProps = {
  onClick: _ => console.log('click handle not overidden')
}

export default Button
