import { useEffect, useRef } from 'react'
import UserIcon from './icon'
import { ChevronLeftIcon } from '@heroicons/react/solid'

const UserButton = ({ account, onClick, onClose, className }) => {
  const iconRef = useRef()
  useEffect(_ => {
    const onDocumentClick = e => {
      if (e.target !== iconRef.current) onClose()
    }
    document.body.addEventListener('click', onDocumentClick, false)
    return _ => document.body.removeEventListener('click', onDocumentClick)
  }, [])

  const onUserProfileClicked = e => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()

    onClick()
  }

  return (<div className='justify-center border-t-4 h-20 pt-2 border-opacity-75 border-red-400'>
      <div ref={iconRef} onClick={onUserProfileClicked} className={'group box-content shadow border-2 rounded-full transform transition-transform duration-200 ease-in-out hover:animate-pulse hover:scale-75 border-accent-blue w-8 h-8 cursor-pointer ' + className}>
        <UserIcon account={account} size={24} className='w-full h-full min-w-max min-h-8 transform transition-transform duration-300 ease-in-out group-hover:scale-150' />
      </div>
    </div>
  )
}

export default UserButton
